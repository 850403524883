import React, { useState, useEffect } from "react";
import "./navbar.css";

function Navbarbasic() {
  return (
    <>
      <div style={{ background: "#F6F6F6" }} className="topNav">
        
        <div>
          <img
            alt=""
            src={window.location.origin + "/img/black.png"}
            width="195"
            height="65"
            className="d-inline-block align-top m-2"
          />
        </div>
      </div>
    </>
  );
}

export default Navbarbasic;
