//const awsmobile = require("../../aws-exports");
import AWS from "aws-sdk";
import Auth from "@aws-amplify/auth";
import createXml from "./createXML";
import axios from "axios";

const uploadToS3 = async (
  file,
  signedURL,
  setpercentage
) => {
  let result = await axios.put(signedURL, file[0], {
    headers: { "Content-Type": file[0].type },
    onUploadProgress: (progressEvent) => {
      //console.log('P Complete',progressEvent.loaded, progressEvent.total)
      let uploaded = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      setpercentage(uploaded);
    },
  });
};

export default uploadToS3;
