import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Register from "./register";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import ApiClient from "./apis/apiClient";

//import Navbar from "./components/user/navbar";
//import Adminnavbar from "./components/admin/adminnavbar";
import Footer from "./components/footer/footer";
//import AdminUserSection from "./components/admin/admin_users_section";
import AdminUserSectionV2 from "./components/admin/user/admin_user_section";
import AdminCompanySection from "./components/admin/admin_company_section";
//import CreateMessage from "./components/user/create-message";

//import IotDeviceNavbar from "./components/iotDeviceDashboard/navbar";
//import IotDeviceHome from "./components/iotDeviceDashboard/home";
import IOTDeviceDash from "./components/iotDeviceDashboard/iotDeviceDash";

import { Amplify }  from "aws-amplify";
//import awsmobile from './aws-exports'
import UserProfile from "./components/user/UserProfile";
import PageNotFound from "./404-notfound";
import ConfirmRegister from "./confirm_register";
import TestAuth from "./components/user/test-auth";
import CreateMessageNew from "./components/user/ScheduleMessage/create-message-new";
import MessageHistory from "./components/user/MessageHistory/mesageHistory";
import RegionStationSection from "./components/admin/region/region_station_section";
import ForgotPassword from "./components/register/forgotPassword";
import CreateMessagePayload from "./components/user/ScheduleMessage/create-message-payload";
import CreateMessageIoTPayload from "./components/user/ScheduleMessage/create-message-iot-payload";
import Unauthorized from "./unauthorized";
import AdminHome from "./components/admin/admin_home";
import About from "./components/general/about";
import NavbarNew from "./components/navbar/Navbar";
import Navbarbasic from "./components/navbar/NavbarBasic";
import DashboardV1 from "./components/general/dashboard";
import ErrorLogs from "./components/admin/errorLogs";
import UpdateStationLists from "./components/msacstations/updateStationLists";
import SystemDashboard from "./components/admin/systemdashboard";
import RecoverMFA from "./components/register/recoverMFA";
import CreateMessagePayloadEVSE from "./components/user/ScheduleMessage/create-message-evse";
import MidasDownload from "./components/midas/midas_download";

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_bucket: process.env.REACT_APP_AWS_BUCKET,
};

Amplify.configure(awsmobile);


const Routing = () => {
  const [showSideBar, setshowSideBar] = useState(false);
  const showSideBarToggle = () => setshowSideBar(!showSideBar);
  return (
    <Router>
      <Switch>
        <Route exact path="/navbar" component={NavbarNew} />
        <Route exact path="/" component={App} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/confirmRegister" component={ConfirmRegister} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/recover-mfa" component={RecoverMFA} />
        <Route
          path="/user/profile"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <UserProfile />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/user/about"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <About />
              <Footer />
            </Fragment>
          )}
        />
        <Route exact path="/user/authenticated" component={TestAuth} />
        <Route
          path="/user/home"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <CreateMessageNew />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/user/xperi-payload"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <CreateMessagePayload />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/user/xperi-payload-evse"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <CreateMessagePayloadEVSE />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/user/xperi-iot-payload"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <CreateMessageIoTPayload />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/user/message-history"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <MessageHistory />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/admin/home"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <AdminHome />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/admin/users"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <AdminUserSectionV2 />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/admin/msac-error-logs"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <ErrorLogs />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/admin/usersv2"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <AdminUserSectionV2 />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/admin/company"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <AdminCompanySection />
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path="/admin/updateStationList"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <UpdateStationLists />
              <Footer />
            </Fragment>
          )}
        />
        {/* <Route
          path="/iot/login"
          render={() => (
             <Fragment>
               <IotDeviceNavbar />
               <IotDeviceHome />
             </Fragment>
           )}
         /> */}
        <Route
          path="/iot/home"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <IOTDeviceDash />
            </Fragment>
          )}
        />
        {
          <Route
            path="/admin/region/stations"
            render={() => (
              <Fragment>
                <NavbarNew
                  showSideBar={showSideBar}
                  setshowSideBar={setshowSideBar}
                  showSideBarToggle={showSideBarToggle}
                />
                <RegionStationSection />
                <Footer />
              </Fragment>
            )}
          />
        }
        <Route
          exact
          path="/dashboard"
          render={() => (
            <Fragment>
              <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
              <DashboardV1 showSideBar={showSideBar} />
              <Footer />
            </Fragment>
          )}
        />
        <Route path="/admin/system-dashboard" render={() => 
          <Fragment>
            <NavbarNew
                showSideBar={showSideBar}
                setshowSideBar={setshowSideBar}
                showSideBarToggle={showSideBarToggle}
              />
          <SystemDashboard />
          <Footer/>
          </Fragment>
        } />
        <Route path="/midas-rate-download" render={() => 
          <Fragment>
            <Navbarbasic/>
            <MidasDownload/>
          <Footer/>
          </Fragment>
        } />
        <Route path="/unauthorized" render={() => <Unauthorized />} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <React.Fragment>
    <Routing />
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
