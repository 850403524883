import React, { useState, useEffect } from "react";
import { Paper, Autocomplete, TextField, Box } from "@mui/material";
import axios from "axios";
import { Button } from "react-bootstrap";
import Loader from "../loader/loader";

function MidasDownload() {
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [loading, setloading] = useState(false);
  const [percentage, setpercentage] = useState(0);
  const [rinData, setrinData] = useState([]);
  const [rinDataValue, setrinDataValue] = useState();
  const signaltype = [
    { label: "All signal types", value: "0" },
    { label: "Tariff signals only", value: "1" },
    { label: "Green House Gas Emissions only", value: "2" },
    { label: "Flex Alerts only", value: "3" },
  ];

  const [signalTypeValue, setsignalTypeValue] = useState(0);

  useEffect(async () => {}, []);

  const getDistibutorList = async () => {
    setloading(true);
    let resp = await axios.get(
      "https://2y5kzl2ymynqnzmnsjbsgokfvy0cmqfp.lambda-url.us-west-2.on.aws/?signal=" +
        signalTypeValue +
        "&query=getrin"
    );
    setrinData(resp.data);
    setloading(false);
  };

  
  const getratesData = async () => {
    setloading(true);
    let resp = await axios.get(
      "https://2y5kzl2ymynqnzmnsjbsgokfvy0cmqfp.lambda-url.us-west-2.on.aws/?rid=" +
        rinDataValue +
        "&query=getvalue"
    );
    const element = document.createElement("a");
    const file = new Blob([resp.data], { type: "text/plain;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = "rate_file-("+rinDataValue+").txt";
    document.body.appendChild(element);
    element.click();
    setloading(false)
  };
  const updateSignal = (event, value) => {
    //console.log(event, value);
    setsignalTypeValue(value.value);
  };

  const updateRateID = (event, value) => {
    console.log(event, value);
    setrinDataValue(value.value);
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <Loader
        loading={loading}
        loaderType={loaderType}
        percentage={percentage}
      ></Loader>
      <Paper className="min-vh-100 p-2 m-4" elevation={0} square={false}>
        <Box
          sx={{
            display: "flex",
            m: 1,
            p: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Autocomplete
            disablePortal
            id="signal_type"
            options={signaltype}
            renderInput={(params) => (
              <TextField {...params} label="Signal Type" />
            )}
            style={{ width: "450px" }}
            onChange={updateSignal}
          />

          <Button onClick={getDistibutorList} style={{ marginLeft: "10px" }}>
            Get Distributor List
          </Button>
        </Box>
        <br />
        {rinData.length > 0 && (
          <Box
            sx={{
              display: "flex",
              m: 1,
              p: 1,
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={rinData}
              sx={{ width: 900 }}
              renderInput={(params) => (
                <TextField {...params} label="Rate ID: Distributer List" />
              )}
              onChange={updateRateID}
            />
            <Button onClick={getratesData} style={{ marginLeft: "10px" }}>
              Download Rates
            </Button>
          </Box>
        )}
      </Paper>
      <br />
      <br />
    </div>
  );
}

export default MidasDownload;
