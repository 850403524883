import React, { PureComponent } from "react";
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import AWS from "aws-sdk";
//import awsmobile from "../../aws-exports";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import Box from "@material-ui/core/Box";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Typography from "@material-ui/core/Typography";
import Multiselect from "multiselect-react-dropdown";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import checkPageAccess from "../others/getPageAccess";
import {
  TextField,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Slider,
} from "@material-ui/core";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import ApiClient from "../../apis/apiClient";
import logout from "../validations/logoutUser";

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_bucket: process.env.REACT_APP_AWS_BUCKET,
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757CE8",
      main: "#3F50B5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF7961",
      main: "#F44336",
      dark: "#BA000D",
      contrastText: "#000",
    },
  },
});

const modeAlias = {
  1: "Scanning",
  4: "Waiting for Data",
  5: "Processing Data",
};

export default class IOTDeviceDash extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      responsive: "standard",
      tableBodyHeight: "600px",
      tableBodyMaxHeight: "",
      tableData: [],
      lastUpdateTime: "",
      tableLoader: true,
      modalState: false,
      ignoreModalState: false,
      deviceIdd: "",
      deviceDetail: {},
      locationState: "",
      locationStreet: "",
      DeviceAlias: "",
      locationCity: "",
      notes: "",
      locationInside: "",
      ignoreDevice: "",
      rowsSelected: [],
      rowsPerPage: 100,
      userAuthToken: null,
      editBtn: false,
      res: "",
      rssiFilterChecked: false,
      msgFilterChecked: false,
      dateFilterChecked: false,
      cdnFilterChecked: false,
      optionsDropdown: [],
      selectedValues: null,
      selectedList: null,
      dateMin: "",
      dateMax: "",
      rssiRange: [],
      cdn0Range: [],
      removeButtonState: false,
      enableButtonState: false,
      docClient: null,
      tableEnable: [],
      tableDisable: [],
      enableIndex: [],
      disableIndex: [],
      //enableIndex:""
    };
    //this.saveModal = this.saveModal.bind(this);
  }

  onRowsSelect = (_, allRows) => {
    this.setState({ rowsSelected: allRows.map((row) => row.index) });
  };
  
  onRowsSelect2 = (curRowSelected, allRowsSelected) => {
    let arrVal = [];
    for (var j = 0; j < allRowsSelected.length; j++) {
      arrVal[j] = allRowsSelected[j].dataIndex;
    }
    arrVal.sort();
    if (allRowsSelected.length > 0) {
      this.setState({
        removeButtonState: true,
        enableButtonState: false,
        enableIndex: arrVal,
      });
    } else {
      this.setState({
        removeButtonState: false,
        enableButtonState: false,
        enableIndex: [],
      });
    }
  };
  onRowsSelect3 = (curRowSelected, allRowsSelected) => {
    let arrVal = [];
    for (var j = 0; j < allRowsSelected.length; j++) {
      arrVal[j] = allRowsSelected[j].dataIndex;
    }
    arrVal.sort();
    if (allRowsSelected.length > 0) {
      this.setState({
        removeButtonState: false,
        enableButtonState: true,
        disableIndex: arrVal,
      });
    } else {
      this.setState({
        removeButtonState: false,
        enableButtonState: false,
        disableIndex: [],
      });
    }
  };
  
  onChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };
  tabledataUpdate = async (dId) => {
    //console.log(!this.state.enableButtonState);
    let response = await ApiClient(
      "post",
      "/admin/updateIoTDeviceInfoState",
      { deviceId: dId, state: this.state.enableButtonState + "" },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      alert("Updated");
    } else if (response.data.status === 403) {
      alert("Session Expired Please Relogin");
      logout();
    } else if (response.data.status === 422) {
      alert("Missing required para");
    } else {
      alert("Something Went Wrong");
    }
  };
  saveDeviceDisableStatus = () => {
    let enableArr = this.state.tableEnable;
    let disableArr = this.state.tableDisable;
    let c = 0;
    //console.log(this.state.enableIndex);
    this.state.enableIndex.forEach((i) => {
      let dId = enableArr[i - c]["DeviceID"];
      disableArr.push({ DeviceID: dId });
      enableArr.splice(i - c, 1);
      c++;
      this.tabledataUpdate(dId);
    });
    this.setState({
      tableEnable: enableArr,
      enableIndex: [],
      tableDisable: disableArr,
    });
    alert("Status Updated Success");
  };
  saveDeviceEnableStatus = () => {
    let enableArr = this.state.tableEnable;
    let disableArr = this.state.tableDisable;
    let c = 0;
    let statusUpdate = [];

    this.state.disableIndex.forEach(async (i) => {
      let dId = disableArr[i - c]["DeviceID"];
      enableArr.push({ DeviceID: dId });
      disableArr.splice(i - c, 1);
      c++;
      this.tabledataUpdate(dId);
    });
    this.setState({
      tableEnable: enableArr,
      tableDisable: disableArr,
      disableIndex: [],
    });
    alert("Status Updated Success");

  };

  saveModal = async () => {

    let response = await ApiClient("post", "/admin/updateIoTDeviceInfoData", {
      deviceId: document.getElementById("deviceId").value,
      locationState: document.getElementById("locationState").value,
      locationStreet: document.getElementById("locationStreet").value,
      deviceAlias: document.getElementById("deviceAlias").value,
      locationCity: document.getElementById("locationCity").value,
      notes: document.getElementById("notes").value,
      locationInside: document.getElementById("locationInside").value,
      ignoreDevice: document.getElementById("ignoreDevice").value
    }, {
      "Content-Type": "application/json",
      Authorization: Cookies.get("auth"),
    })

    if (response.data.status === 200) {
      let a = this.state.deviceDetail;
      a[document.getElementById("deviceId").value][0] =
        document.getElementById("locationState").value;
      a[document.getElementById("deviceId").value][1] =
        document.getElementById("locationStreet").value;
      a[document.getElementById("deviceId").value][2] =
        document.getElementById("deviceAlias").value;
      a[document.getElementById("deviceId").value][3] =
        document.getElementById("locationCity").value;
      a[document.getElementById("deviceId").value][4] =
        document.getElementById("notes").value;
      a[document.getElementById("deviceId").value][5] =
        document.getElementById("locationInside").value;
      a[document.getElementById("deviceId").value][6] =
        document.getElementById("ignoreDevice").value;

      ////console.log(a[document.getElementById("deviceId").value][0] + "nnnn");
      this.setState({
        deviceDetail: a,
        modalState: false,
        deviceIdd: "",
        locationStreet: "",
        DeviceAlias: "",
        locationCity: "",
        notes: "",
        locationInside: "",
        locationState: "",
        ignoreDevice: "",
        editBtn: false,
      });
    } else if (response.data.status === 403) {
      alert("Session Expired Please Relogin");
      logout();
    } else if (response.data.status === 422) {
      alert("Missing required para");
    } else {
      alert("Something Went Wrong");
    }
  };
  closeModal = () => {
    this.setState({
      modalState: false,
      deviceIdd: "",
      locationStreet: "",
      DeviceAlias: "",
      locationCity: "",
      notes: "",
      locationInside: "",
      locationState: "",
      ignoreDevice: "",
      editBtn: false,
    });
  };
  delete = () => {
    //var filterArray =[]
    var tableData_copy = this.state.tableData;
    this.state.selectedList.forEach((element, index, array) => {
      var filterArray = tableData_copy.filter(
        (obj) => obj.DeviceID !== element.name
      );
      tableData_copy = filterArray;
    });
    this.setState({
      tableData: tableData_copy,
    });
  };
  hover = (value) => {
    ////console.log(value+"hhhhbbbbs")

    var a = this.state.deviceDetail;
    this.setState({
      modalState: !this.state.modalState,
      deviceIdd: value,
      locationState: a[value][0],
      locationStreet: a[value][1],
      DeviceAlias: a[value][2],
      locationCity: a[value][3],
      notes: a[value][4],
      locationInside: a[value][5],
      ignoreDevice: a[value][6],
    });
    ////console.log(a[value]);
  };
  componentWillUnmount() {
    //clearTimeout(this.timer);
  }
  validateUser = async () => {
    let response = await ApiClient(
      "post",
      "/admin/getTableDataDynamoDB",
      {
        tableName: "IotDevice_info",
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      let data = response.data.result;
      let detail = {};
      let optionsArray = [];
      let enable = [];
      let disable = [];
      for (let x = 0; x < data.length; x++) {
        optionsArray.push({ name: data[x]["DeviceID"] });
        detail[data[x]["DeviceID"]] = [
          data[x]["Location_State"],
          data[x]["Location_street"],
          data[x]["DeviceAlias"],
          data[x]["Location_City"],
          data[x]["Notes:"],
          data[x]["Locaton_inside"],
          data[x]["IgnoreDevice"],
        ];
        if (data[x]["IgnoreDevice"] === "true") {
          enable.push({
            DeviceID: data[x]["DeviceID"],
          });
        }
        if (data[x]["IgnoreDevice"] === "false") {
          disable.push({
            DeviceID: data[x]["DeviceID"],
          });
        }
      }
      //console.log(enable);
      //console.log(disable);
      this.setState(
        {
          deviceDetail: detail,
          optionsDropdown: optionsArray,
          tableEnable: enable,
          tableDisable: disable,
        },
        () => {
          this.deviceTableScan();
        }
      );
    } else if (response.data.status === 403) {
      alert("Session Expired Please Relogin");
      logout();
    } else if (response.data.status === 422) {
      alert("Missing required para");
    } else {
      alert("Problem in Loading Data reload");
    }
  };

  deviceTableScan = async () => {
    let response = await ApiClient(
      "post",
      "/admin/getTableDataDynamoDB",
      {
        tableName: "IOT_Device_Details",
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      let data = response.data.result;
      let rowData;
      let tempTableData = [];
      let cMin = 0;
      let cMax = 0;
      let rMin = 0;
      let rMax = 0;

      for (let x = 0; x < data.length; x++) {
        rowData = data[x];
        if(this.state.deviceDetail[rowData["DeviceID"]] === "HD4391Dev_003")
          console.log('x',this.state.deviceDetail[rowData["DeviceID"]][6])
        if (this.state.deviceDetail[rowData["DeviceID"]][6] === "true")
          continue;
        cMin = Math.min(
          cMin,
          isNaN(parseInt(rowData["cdn0"].trim()))
            ? cMin
            : parseInt(rowData["cdn0"])
        );
        cMax = Math.max(
          cMax,
          isNaN(parseInt(rowData["cdn0"].trim()))
            ? cMax
            : parseInt(rowData["cdn0"])
        );
        rMin = Math.min(
          rMin,
          isNaN(parseInt(rowData["rssi"])) ? rMin : parseInt(rowData["rssi"])
        );
        rMax = Math.max(
          rMax,
          isNaN(parseInt(rowData["rssi"])) ? rMax : parseInt(rowData["rssi"])
        );

        let d = new Date(rowData["Date"].substring(0, 10));
        let dd = d.getDate();
        let mm = d.getMonth() + 1;
        let yyyy = d.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }

        if (mm < 10) {
          mm = "0" + mm;
        }

        d = mm + "-" + dd + "-" + yyyy;

        tempTableData.push({
          DeviceID: rowData["DeviceID"],
          "Location street": this.state.deviceDetail[rowData["DeviceID"]][1],
          Date: d,
          Timestamp: rowData["Date"],
          Freq: parseInt(rowData["Freq"]),
          cdn0: isNaN(parseInt(rowData["cdn0"]))
            ? 0
            : parseInt(rowData["cdn0"]),
          mode: !modeAlias[rowData["mode"]]
            ? rowData["mode"]
            : modeAlias[rowData["mode"]],
          rssi: isNaN(parseInt(rowData["rssi"]))
            ? 0
            : parseInt(rowData["rssi"]),
          msgs: rowData["msgs"],
          msgID: rowData["MsgID"],
          PacketSize: rowData["AASPktSize"]
        });
      }

      this.setState({
        tableData: tempTableData,
        tableLoader: false,
        rssiRange: [rMin, rMax],
        cdn0Range: [cMin, cMax],
      });
    } else if (response.data.status === 403) {
      alert("Session Expired Please Relogin");
      logout();
    } else if (response.data.status === 422) {
      alert("Missing required para");
    } else {
      alert("Problem in Loading Data reload");
    }
  };

  async componentDidMount() {
    document.title = "IoT Device Dashboard | Xperi HD Radio IoT";
    let isValid = await checkPageAccess();
    if (!isValid) {
      if (!alert("access denied for the url"))
        window.location.href = "/unauthorized";
      return;
    } else {
      console.log("You have access");
    }
    if (!Cookies.get("auth")) {
      Auth.signOut().then((res) => {
        //console.log(res);
        this.validateUser();
      });
      //console.log("Logged out");
    } else {
      this.setState({ userAuthToken: Cookies.get("auth") });
      this.validateUser();
    }
  }
  render() {
    const columns2 = [
      {
        name: "DeviceID",
        label: "Device ID",
        options: {
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 101,
            },
          }),
        },
      },
      // {
      //   name: "LocationState",
      //   label: "Location State",
      // },
      // {
      //   name: "LocationStreet",
      //   label: "Location Street",
      // },
      // {
      //   name: "LocationCity",
      //   label: "Location City",
      // },
      // {
      //   name: "Notes",
      //   label: "Notes",
      // },
      // {
      //   name: "LocatonInside",
      //   label: "Locaton Inside",
      // },
      // {
      //   name: "IgnoreDevice",
      //   label: "Ignore Device",
      // },
    ];
    const columns = [
      {
        name: "DeviceID",
        options: {
          customBodyRender: (value, tableMeta, updateValuep) => {
            return (
              <a
                onClick={(e) => this.hover(value)}
                style={{ color: "blue", whiteSpace: "nowrap" }}
              >
                {value}
              </a>
            );
          },
        },
      },
      {
        name: "Location street",
        options: {
          display: false,
        },
      },
      {
        name: "Date",
        options: {
          display: false,
          filter: true,
          // filterList: [],
          filterType: "custom",
          type: "date",
          customFilterListOptions: {
            render: (v) => {
              if (v[0] && v[1] && this.state.dateFilterChecked) {
                return [`Min date: ${v[0]}`, `Max date: ${v[1]}`];
              } else if (v[0] && v[1] && !this.state.dateFilterChecked) {
                return `Min date: ${v[0]}, Max date: ${v[1]}`;
              } else if (v[0]) {
                return `Min date: ${v[0]}`;
              } else if (v[1]) {
                return `Max date: ${v[1]}`;
              }
              return false;
            },
            update: (filterList, filterPos, index) => {
              //console.log(
              //   "customFilterListOnDelete: ",
              //   filterList,
              //   filterPos,
              //   index
              // );
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }
              return filterList;
            },
          },

          filterOptions: {
            names: [],
            logic(dt, filters) {
              if (filters[0] && filters[1]) {
                return dt < filters[0] || dt > filters[1];
              } else if (filters[0]) {
                return dt < filters[0];
              } else if (filters[1]) {
                return dt > filters[1];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Date</FormLabel>
                <FormGroup row>
                  <TextField
                    type="date"
                    value={this.state.dateMin}
                    onChange={(event) => {
                      this.setState({
                        dateMin: event.target.value,
                      });
                      var mm = new Date(event.target.value).getMonth() + 1;
                      var dd = new Date(event.target.value).getDate();
                      var yy = new Date(event.target.value).getFullYear();
                      if (mm < 10) mm = "0" + mm;
                      if (dd < 10) dd = "0" + dd;
                      filterList[index][0] = mm + "-" + dd + "-" + yy;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "48%", marginRight: "2%" }}
                  />
                  <TextField
                    type="date"
                    value={this.state.dateMax}
                    onChange={(event) => {
                      this.setState({
                        dateMax: event.target.value,
                      });
                      var mm = new Date(event.target.value).getMonth() + 1;
                      var dd = new Date(event.target.value).getDate();
                      var yy = new Date(event.target.value).getFullYear();
                      if (mm < 10) mm = "0" + mm;
                      if (dd < 10) dd = "0" + dd;
                      filterList[index][1] = mm + "-" + dd + "-" + yy;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: "48%" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.dateFilterChecked}
                        onChange={(event) =>
                          this.setState({
                            dateFilterChecked: event.target.checked,
                          })
                        }
                      />
                    }
                    label="Separate Values"
                    style={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: "Timestamp",
        label: "Timestamp (UTC)",
        options: {
          filter: false,
        },
      },
      {
        name: "Freq",
        label: "Freq",
        options: {
          sort: true,
        },
      },
      {
        name: "cdn0",
        label: "cdn0",
        options: {
          sort: true,
          filter: true,
          // filterList: [],
          filterType: "custom",
          customFilterListOptions: {
            render: (v) => {
              if (v[0] && v[1] && this.state.cdnFilterChecked) {
                return [`Min cdn0: ${v[0]}`, `Max cdn0: ${v[1]}`];
              } else if (v[0] && v[1] && !this.state.cdnFilterChecked) {
                return `Min cdn0: ${v[0]}, Max cdn0: ${v[1]}`;
              } else if (v[0]) {
                return `Min cdn0: ${v[0]}`;
              } else if (v[1]) {
                return `Max cdn0: ${v[1]}`;
              }
              return false;
            },
            update: (filterList, filterPos, index) => {
              //console.log(
              //   "customFilterListOnDelete: ",
              //   filterList,
              //   filterPos,
              //   index
              // );
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }
              return filterList;
            },
          },

          filterOptions: {
            names: [],
            logic(cdn0, filters) {
              if (filters[0] && filters[1]) {
                return cdn0 < filters[0] || cdn0 > filters[1];
              } else if (filters[0]) {
                return cdn0 < filters[0];
              } else if (filters[1]) {
                return cdn0 > filters[1];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>cdn0</FormLabel>
                <FormGroup row>
                  <Slider
                    //getAriaLabel={() => 'Temperature range'}
                    value={this.state.cdn0Range}
                    onChange={(event, newValue) =>
                      this.setState({ cdn0Range: newValue }, () => {
                        filterList[index][0] = this.state.cdn0Range[0];
                        filterList[index][1] = this.state.cdn0Range[1];
                        onChange(filterList[index], index, column);
                      })
                    }
                    valueLabelDisplay="auto"
                  // getAriaValueText={valuetext}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: "PacketSize",
      },
      {
        name: "mode",
        label: "mode",
        options: {
          sort: false,
        },
      },
      {
        name: "rssi",
        label: "rssi",
        options: {
          sort: true,
          filter: true,
          // filterList: [],
          filterType: "custom",
          customFilterListOptions: {
            render: (v) => {
              if (v[0] && v[1] && this.state.rssiFilterChecked) {
                return [`Min rssi: ${v[0]}`, `Max rssi: ${v[1]}`];
              } else if (v[0] && v[1] && !this.state.rssiFilterChecked) {
                return `Min rssi: ${v[0]}, Max rssi: ${v[1]}`;
              } else if (v[0]) {
                return `Min rssi: ${v[0]}`;
              } else if (v[1]) {
                return `Max rssi: ${v[1]}`;
              }
              return false;
            },
            update: (filterList, filterPos, index) => {
              //console.log(
              //   "customFilterListOnDelete: ",
              //   filterList,
              //   filterPos,
              //   index
              // );
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }
              return filterList;
            },
          },

          filterOptions: {
            names: [],
            logic(rssi, filters) {
              if (filters[0] && filters[1]) {
                return rssi < filters[0] || rssi > filters[1];
              } else if (filters[0]) {
                return rssi < filters[0];
              } else if (filters[1]) {
                return rssi > filters[1];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>rssi</FormLabel>
                <FormGroup row>
                  <Slider
                    //getAriaLabel={() => 'Temperature range'}
                    value={this.state.rssiRange}
                    onChange={(event, newValue) =>
                      this.setState({ rssiRange: newValue }, () => {
                        filterList[index][0] = this.state.rssiRange[0];
                        filterList[index][1] = this.state.rssiRange[1];
                        onChange(filterList[index], index, column);
                      })
                    }
                    valueLabelDisplay="auto"
                  // getAriaValueText={valuetext}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: "msgs",
        label: "msgs",
        options: {
          filter: true,
          filterType: "custom",
          filterType: "dropdown",
          print: false,
          sort: false,
        },
      },
      {
        name: "msgID",
        label: "msgID",
        options: {
          sort: false,
        },
      },
    ];
    const options2 = {
      filter: false,
      filterType: "dropdown",
      print: false,
      viewColumns: false,
      download: false,
      search: false,
      pagination: false,
      tableBodyHeight: "300px",
      tableBodyMaxHeight: "",
      responsive: this.state.responsive,
      selectableRows: "multiple",
      customToolbarSelect: () => { },
      //selectableRows: false,
      onRowsSelect: this.onRowsSelect2,
      rowsSelected: this.state.enableIndex,
      //searchable: false,
    };
    const options3 = {
      filter: false,
      filterType: "dropdown",
      print: false,
      viewColumns: false,
      download: false,
      search: false,
      pagination: false,
      tableBodyHeight: "300px",
      tableBodyMaxHeight: "",
      responsive: this.state.responsive,
      selectableRows: "multiple",
      rowsSelected: this.state.disableIndex,
      customToolbarSelect: () => { },
      //selectableRows: false,
      onRowsSelect: this.onRowsSelect3,
    };
    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: this.state.responsive,
      tableBodyHeight: this.state.tableBodyHeight,
      tableBodyMaxHeight: this.state.tableBodyMaxHeight,
      selectableRows: "none",
      rowsSelected: this.state.rowsSelected,
      onRowSelectionChange: this.onRowsSelect,
      rowsPerPage: this.state.rowsPerPage,
      onChangeRowsPerPage: this.onChangeRowsPerPage,
      rowsPerPageOptions: [10, 25, 50, 75, 100],

      //onChangeRowsPerPage:{{()=>{this.setState(rowPerpage:number)}}},
      downloadOptions: {
        filename: "tableDownload.csv",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      sortOrder: {
        name: "Timestamp",
        direction: "desc",
      },
      customToolbar: () => {
        return (
          <Tooltip title={"Refresh"}>
            <IconButton style={{ order: -1 }}>
              <RefreshIcon onClick={() => this.deviceTableScan()} />
            </IconButton>
          </Tooltip>
        );
      },
    };
    return (
      <div>
        {this.state.userAuthToken !== null && (
          <div
            className="row d-flex justify-content-center mt-100"
            style={{ marginTop: "100px" }}
          >
            <div className="col-md-10">
              <div className="card" style={{ padding: "10px" }}>
                <div
                  className="x_title"
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #E6E9ED",
                    padding: "1px 5px 6px",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                </div>
                {/* <span id="Apercent" style={{ textAlign: "center" }}>
                <h5>Last Updated: {this.state.lastUpdateTime}</h5>
              </span> */}
                <div
                  className="x_title"
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #E6E9ED",
                    padding: "1px 5px 6px",
                    marginBottom: "10px",
                  }}
                >
                  <span id="Apercent" style={{ textAlign: "center" }}>
                    <h6>
                      <p>IOT Device Details</p>
                      {/* <small>Updated every 10 seconds</small> */}
                    </h6>
                  </span>
                </div>
                <button
                  className="btn btn-primary"
                  style={{ width: "15%", alignSelf: "end" }}
                  onClick={() => this.setState({ ignoreModalState: true })}
                >
                  Add/Remove Devices
                </button>
                <React.Fragment>
                  {/*  */}
                  <br />
                  <br />
                  {this.state.tableLoader === true && (
                    <div style={{ minHeight: "400px", width: "100%" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                        }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{
                          position: "absolute",
                          left: "45%",
                          top: "60%",
                        }}
                      >
                        <Button> Loading Table Data </Button>
                      </Typography>
                    </div>
                  )}
                  {this.state.tableLoader === false && (
                    <div>
                      <MuiThemeProvider theme={theme}>
                        <MUIDataTable
                          data={this.state.tableData}
                          columns={columns}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>
                  )}
                </React.Fragment>
                <Modal
                  show={this.state.modalState}
                  onHide={() => this.closeModal()}
                  animation={false}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Iot Device Detail</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <FormControl style={{ width: "100%" }}>
                      <div className="row">
                        <div className="col-md-6">
                          Device Id
                          <br />
                          <TextField
                            disabled
                            id="deviceId"
                            //label="Disabledddd"
                            value={this.state.deviceIdd}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          Location State
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="locationState"
                            //label="Disabledddd"
                            defaultValue={this.state.locationState}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          Location Street
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="locationStreet"
                            //label="Disabledddd"
                            defaultValue={this.state.locationStreet}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          Device Alias
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="deviceAlias"
                            //label="Disabledddd"
                            defaultValue={this.state.DeviceAlias}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          Location City
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="locationCity"
                            //label="Disabledddd"
                            defaultValue={this.state.locationCity}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          Notes
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="notes"
                            //label="Disabledddd"
                            defaultValue={this.state.notes}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          Locaton Inside
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="locationInside"
                            //label="Disabledddd"
                            defaultValue={this.state.locationInside}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          Ignore Device
                          <br />
                          <TextField
                            disabled={!this.state.editBtn ? true : false}
                            id="ignoreDevice"
                            //label="Disabledddd"
                            defaultValue={this.state.ignoreDevice}
                            variant="filled"
                            inputProps={{
                              style: { width: "100%", color: "#0f1010" },
                            }}
                          />
                        </div>
                      </div>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    {!this.state.editBtn && (
                      <button
                        className="btn btn-primary text-center"
                        onClick={() =>
                          this.setState({ editBtn: !this.state.editBtn })
                        }
                      >
                        Edit
                      </button>
                    )}
                    {this.state.editBtn && (
                      <button
                        className="btn btn-primary text-center"
                        onClick={() => this.saveModal()}
                      >
                        Save
                      </button>
                    )}
                  </ModalFooter>
                </Modal>

                <Modal
                  show={this.state.ignoreModalState}
                  onHide={() => this.setState({ ignoreModalState: false })}
                  animation={false}
                  size="xl"
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Enable / Disable Devices</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <div className="container">
                      {/* <div className="row">
                        <div className="col-md-6">
                          <Button
                            variant="primary"
                            size="lg"
                            style={{ height: "100%" }}
                            onClick={this.saveDeviceEnableStatus}
                            disabled={!this.state.enableButtonState}
                          >
                            Remove Device
                          </Button>
                        </div>
                        <div className="col-md-6">
                          <Button
                            variant="primary"
                            size="lg"
                            style={{ height: "100%" }}
                            onClick={this.saveDeviceDisableStatus}
                            disabled={!this.state.removeButtonState}
                          >
                            Enable Device
                          </Button>
                        </div>
                      </div> */}
                      <br />
                      <br />
                      <div className="row">
                        <div
                          className="col-md-5"
                          style={{ paddingLeft: "1px" }}
                        >
                          <div id="loaderDiv">
                            <React.Fragment>
                              <div>
                                <MuiThemeProvider theme={theme}>
                                  <MUIDataTable
                                    title="Enabled Devices"
                                    data={this.state.tableDisable}
                                    columns={columns2}
                                    options={options3}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </React.Fragment>
                          </div>
                        </div>
                        <div
                          className="col-md-2"
                          style={{ marginTop: "117px" }}
                        >
                          <Button
                            style={{ height: "41px" }}
                            variant="primary"
                            onClick={this.saveDeviceEnableStatus}
                            disabled={!this.state.enableButtonState}
                          >
                            <ArrowForwardIosIcon /> Disable &nbsp;
                          </Button>
                          <br />
                          <br />
                          <Button
                            style={{ height: "41px" }}
                            variant="primary"
                            onClick={this.saveDeviceDisableStatus}
                            disabled={!this.state.removeButtonState}
                          >
                            <ArrowBackIosIcon /> Enable &nbsp;
                          </Button>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingLeft: "1px" }}
                        >
                          <div id="loaderDiv">
                            <React.Fragment>
                              <div>
                                <MuiThemeProvider theme={theme}>
                                  <MUIDataTable
                                    title="Disabled Devices"
                                    data={this.state.tableEnable}
                                    columns={columns2}
                                    options={options2}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </React.Fragment>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                    <br />
                  </ModalBody>
                </Modal>
              </div>
              <br />
            </div>
          </div>
        )}
        {this.state.userAuthToken === null && (
          <center>
            <div>
              <span>
                <h1>Session Expired !!!... IOT Device </h1>
              </span>
            </div>
          </center>
        )}
      </div>
    );
  }
}
